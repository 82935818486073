import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import SearchNotes from "../components/searchNotes"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Atul Prd"
    const { data, navigate, location } = this.props
    const posts = data.allMdx.edges
    const localSearchBlog = data.localSearchBlog
  
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`Blog`, `Product Design`, `Branding`, `UX Design`, `Product Designer`, `UX/UI`, `App Design`, `Brand Identity`]}
        />
        <img style={{ margin: 0, marginTop: 10  }} src="https://res.cloudinary.com/atulprd/image/upload/v1599187149/Atul-Pradhananga-Picture_icudmy.png" alt="Atul Pradhananga"/>
        <h2 style= {{ lineHeight: 1.3 , marginTop: `30px` }}>
        Hi, I'm Atul — <i>and i make people fall in love with products & brands.</i>
          </h2>
        <p>
        I'm a product designer & I love working with teams to innovate and improve products while keeping user needs, product strategy and business objectives in mind.
        </p>
    <p>
    Here, I explore topics I'm interested in like product design, brand strategy, marketing, philosophy, creative entrepreneurship, ux research, mental models, & more.
    </p>
    <p>
    If you're interested in these topics, you should sign up for my <a href={`https://atulprd.substack.com/subscribe`} target="_blank" rel="noopener noreferrer">newsletter</a>, where I share and discuss ideas, resources and questions to sharpen your thinking and change your perspective on business, life & tech. 
    </p>
    <Link to={`https://atulprd.substack.com/subscribe`} target="_blank" rel="noopener noreferrer">
          <Button marginTop="35px">Join the newsletter</Button>
    </Link>
    <br/>
    <h2>Personal Favorites</h2>
    <li><b><a href={`https://www.atulprd.com/blog/what-makes-great-brands-great`}>What Makes Great Brands Great</a></b> – thoughts on building a brand that matters.</li>
    <li><b><a href={`https://www.atulprd.com/blog/design-for-delight`}>Designing for Delight</a></b> – how to design products that delight the users.</li>
    <li><b><a href={`https://www.atulprd.com/blog/think-like-a%20detective`}>Think Like a Detective</a></b> — my process for ux research.</li>
    <br/>
        <h3>Most recent writings</h3>
        <SearchNotes
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
   <br/>
   <hr/>
    <h4>Get in touch</h4>
    <p>
    I’m always happy to meet people building interesting things. Email me at: <a href={`mailto:hi@atulprd.com`} target="_blank" rel="noopener noreferrer">hi@atulprd.com</a>
    </p>
    <hr/>
        <h4>elsewhere</h4>
          <p> 
            <a href={`https://www.twitter.com/atulprd`} target="_blank" rel="noopener noreferrer"> Twitter</a> · <a href={`https://www.instagram.com/atulprd`} target="_blank" rel="noopener noreferrer"> Instagram </a> · <a href={`https://www.linkedin.com/in/atulpradhananga`} target="_blank" rel="noopener noreferrer"> LinkedIn </a>
        </p>
        <p> © {new Date().getFullYear()},
          {` `}
          <a href="https://www.twitter.com/atulprd" target="_blank" rel="noopener noreferrer">Atul Pradhananga</a></p>
          <div className="BlogSub">
        <h3>Let's continue the discussion <span role="img" aria-label>💬</span> </h3>
        <p>If you're interested in such topics, you should sign up for my newsletter, where I share and discuss ideas, resources and questions to sharpen your thinking and change your perspective on business, life & tech. </p>
        <Link to={`https://atulprd.substack.com/subscribe`} target="_blank" rel="noopener noreferrer">
          <Button marginTop="35px">Join the newsletter</Button>
        </Link>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(
      sort: { 
        fields: [frontmatter___date],
        order: DESC }
        limit: 4
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
